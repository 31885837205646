import { ProviderCategory_Enum } from '~/hasura/__generated__';
import { t } from 'i18next';

export enum Day {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
}

export const weekdays = [
  {
    id: `${Day.Sun}`,
    label: 'Sunday',
    value: Day.Sun,
  },
  {
    id: `${Day.Mon}`,
    label: 'Monday',
    value: Day.Mon,
  },
  {
    id: `${Day.Tue}`,
    label: 'Tuesday',
    value: Day.Tue,
  },
  {
    id: `${Day.Wed}`,
    label: 'Wednesday',
    value: Day.Wed,
  },
  {
    id: `${Day.Thu}`,
    label: 'Thursday',
    value: Day.Thu,
  },
  {
    id: `${Day.Fri}`,
    label: 'Friday',
    value: Day.Fri,
  },
  {
    id: `${Day.Sat}`,
    label: 'Saturday',
    value: Day.Sat,
  },
];

export function currencyFormatter(value: number | string, currency = 'CAD') {
  if (value === '' || !value) value = 0;
  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      maximumFractionDigits: 2,
    })
      .format(Number(value))
      //Add space between currency symbol and value
      .replace(/^(\D+)/, '$1 ')
      .replace(/\s+/, ' ')
  );
}

export function percentageFormatter(value: number | string) {
  if (value === '') return '';
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(value));
}

export function daysOfWeekFormatter(days?: string[] | null) {
  return days?.map((d) => Day[d as unknown as Day]);
}

export function convertDayIndexesToDaysOfWeek(days?: number[] | null) {
  return days?.map((d) => Object.keys(Day)[Object.values(Day).indexOf(d)]);
}

type TDurationWithTimeOptions = {
  minutesLabel?: string;
  hoursLabel?: string;
  hideValue?: boolean;
  hideUnits?: boolean;
};
type THoursFormatterWithTimeOptions = {
  daysLabel?: string;
  hoursLabel?: string;
  hideValue?: boolean;
  hideUnits?: boolean;
};

export function getDurationWithTimeUnit(
  duration: number,
  options: TDurationWithTimeOptions = {
    minutesLabel: t('labels.minutes'),
    hoursLabel: t('labels.hours'),
    hideValue: false,
    hideUnits: false,
  },
) {
  const isMinutes = duration < 1;
  let result = '';
  if (!options.hideValue) {
    result += Math.round((isMinutes ? duration * 60 : duration) * 100) / 100;
  }
  if (!options.hideUnits) {
    result += ' ' + (isMinutes ? options.minutesLabel : options.hoursLabel);
  }
  return result;
}

export function getHourDayWithTimeUnit(
  duration: number, // in hours
  options: THoursFormatterWithTimeOptions = {
    daysLabel: t('labels.days'),
    hoursLabel: t('labels.hours'),
    hideValue: false,
    hideUnits: false,
  },
) {
  const isHours = duration < 24 || duration % 24 !== 0;
  let result = '';
  if (!options.hideValue) {
    result += isHours ? duration : duration / 24;
  }
  if (!options.hideUnits) {
    result += ' ' + (isHours ? options.hoursLabel : options.daysLabel);
  }
  return result;
}

export const providerCategoriesLabelsTags: Record<
  ProviderCategory_Enum,
  string
> = {
  [ProviderCategory_Enum.ArtsActivities]:
    'pages.register.serviceProvider.artActivities',
  [ProviderCategory_Enum.CarCare]: 'pages.register.serviceProvider.carCare',
  [ProviderCategory_Enum.HealthCare]:
    'pages.register.serviceProvider.healthCare',
  [ProviderCategory_Enum.HouseCare]: 'pages.register.serviceProvider.houseCare',
  [ProviderCategory_Enum.PetCare]: 'pages.register.serviceProvider.petCare',
  [ProviderCategory_Enum.SelfCare]: 'pages.register.serviceProvider.selfCare',
};

export const providerCategoriesLabels = Object.entries(
  providerCategoriesLabelsTags,
).reduce(
  (acc, [key, value]) => {
    return { ...acc, [key]: t(value) };
  },
  {} as Record<ProviderCategory_Enum, string>,
);

export const AppointmentFeesLabels: Record<string, string> = {
  travelFees: 'labels.travelFees',
  deposit: 'labels.deposit',
};

export const formatMonth = (month: number) => (month < 10 ? '0' : '') + month;

export const ProviderCategoriesLabels: Record<ProviderCategory_Enum, string> = {
  [ProviderCategory_Enum.ArtsActivities]:
    'pages.register.serviceProvider.artActivities',
  [ProviderCategory_Enum.CarCare]: 'pages.register.serviceProvider.carCare',
  [ProviderCategory_Enum.HealthCare]:
    'pages.register.serviceProvider.healthCare',
  [ProviderCategory_Enum.HouseCare]: 'pages.register.serviceProvider.houseCare',
  [ProviderCategory_Enum.PetCare]: 'pages.register.serviceProvider.petCare',
  [ProviderCategory_Enum.SelfCare]: 'pages.register.serviceProvider.selfCare',
};
